<template>
  <!-- :class="ShowMainPage === 'Y' ? 'pa-0 custom-bg' : 'pa-0'" -->
  <v-container
      :class="ShowMainPage === 'Y' ? 'pa-0 custom-bg' : 'pa-0'"
      fluid
      fill-height>
    <v-container>
      <v-row>
        <v-col cols="12">
          <slot />
        </v-col>
        <v-col cols="12" class="mb-0 pb-0">
          <span class="display-3"><strong>{{ $t('MeeTeeMeeNgern1.promotions') }}</strong></span>
          <v-divider></v-divider>
        </v-col>
        <!-- <promotions-feed-card
          v-for="(article, i) in paginatedpromotions"
          :key="article.title"
          :size="layout[i]"
          :value="article"
        /> -->
        <promotions-feed-card
          v-for="(article, i) in vDataTable.data"
          :key="article.skey"
          :size="layout[i]"
          :value="article"
        />
      </v-row>

      <v-row align="center">
        <v-col cols="3">
          <base-btn
            v-if="page !== 1"
            class="ml-0"
            square
            title="Previous page"
            @click="page--"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'Feed',
    props: {
      limitShow: {
        type: String,
        required: false,
      },
      ShowMainPage: {
        type: String,
        required: false,
      },
    },
    components: {
      PromotionsFeedCard: () => import('@/components/PromotionsFeedCard'),
    },
    async created () {
      await this.retrieveFrontPromotions(localStorage.getItem('language'))
    },
    data: () => ({
      layout: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      layoutProducgt: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      page: 1,
      promotion: require('@/data/promotion.json'),
      product: require('@/data/product.json'),
      vDataTable: {
        search: '',
        selected: [],
        data: [],
        editedIndex: -1,
        editedItem: {
          win_name: '',
          window_comment: ''
        },
        defaultItem: {
          win_name: '',
          window_comment: ''
        },
        editedLanguageIndex: -1,
        editedLanguageItem: {
        },
        defaultLanguageItem: {
        },
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
          // mustSort: true
        },
        expanded: [],
      },
    }),

    computed: {
      ...mapState(['promotions']),
      pages () {
        return Math.ceil(this.promotions.length / 11)
      },
      paginatedpromotions () {
        const start = (this.page - 1) * 11
        const stop = this.page * 11

        return this.promotions.slice(start, stop)
      },
      paginatedPromotion () {
        const start = (this.page - 1) * 11
        const stop = this.page * 11

        return this.promotion.slice(start, stop)
      },
      paginatedProduct () {
        const start = (this.page - 1) * 11
        const stop = this.page * 11

        return this.product.slice(start, stop)
      },
    },

    watch: {
      page () {
        window.scrollTo(0, 0)
      },
    },
    methods: {
      async selectLanguage (language) {
        await this.retrieveFrontPromotions(language)
      },
      async retrieveFrontPromotions (languageID) {
        var aLimitShow = this.limitShow
        if (!aLimitShow) { aLimitShow = 0 }
        await this.axios.post(process.env.VUE_APP_API + '/promotions/retrieveFrontPromotions' + process.env.VUE_APP_DATABASE_TYPE, {
          limit: aLimitShow,
          language_id: languageID,
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.vDataTable.data = response.data.data
              console.log(this.vDataTable.data, 'this.vDataTable.data')
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    }
  }
</script>
<style scoped>
  .custom-bg {
    background: rgb(242, 245, 247) !important;
    /* background: url('../../assets/bg/bg1.jpg');
    background-size: cover; */
  }
</style>
